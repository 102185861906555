// src/utils/parseTrainTestSplit.ts

export const parseTrainTestSplit = async (): Promise<Record<string, 'train' | 'test'>> => {
    const response = await fetch('https://storage.googleapis.com/smartbirds-assets/train_test_split.txt');
    
    if (!response.ok) {
      throw new Error('Failed to fetch train_test_split.txt');
    }
    
    const textData = await response.text();
  
    const splitData: Record<string, 'train' | 'test'> = {};
  
    textData.split('\n').forEach(line => {
      const [imageId, splitType] = line.trim().split(' ');
      if (imageId && splitType !== undefined) {
        splitData[imageId] = splitType === '0' ? 'train' : 'test';
      }
    });
  
    console.log('Parsed Train/Test Split:', splitData); // Debugging line
  
    return splitData;
  };
  