import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box } from '@mui/material';

const Feedback: React.FC = () => {
  const [feedback, setFeedback] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Feedback submitted:', feedback);
    setFeedback('');
    setSubmitted(true);
  };

  return (
    // Outer Box to hold the background image
    <Box
      sx={{
        backgroundImage: `url('/mybird.jpg')`, // Background image from the public folder
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh', // Ensures full viewport height
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Center the form vertically and horizontally
        padding: '40px 20px',
      }}
    >
      <Container
        maxWidth="sm"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.85)', // Transparent white background for readability
          padding: '40px',
          borderRadius: '10px', // Rounded corners for a modern look
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Slight shadow for better visual hierarchy
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Feedback
        </Typography>
        {submitted ? (
          <Typography variant="body1" color="primary" align="center">
            Thank you for your feedback!
          </Typography>
        ) : (
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <TextField
              label="Your Feedback"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              required
              sx={{
                backgroundColor: 'white', // Ensure input is fully opaque
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3, padding: '10px 0', fontSize: '16px' }} // Make the button bigger for better UX
            >
              Submit
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Feedback;
