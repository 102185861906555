// src/pages/PrivacyPolicy.tsx

import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  return (
    //
    <Box
    sx={{
      backgroundImage: `url('/mybird.jpg')`, // Background image
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '40px',
    }}
  >
    <Container maxWidth="md" style={{ padding: '40px 20px' }}>
      <Typography variant="h3" gutterBottom align="center">
        Privacy Policy
      </Typography>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to SmartBirds.io. We value your privacy and are committed to protecting your personal information. 
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We currently collect no direct information, unless you choose to share it via the feedback functions.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
         We do not sell personal information to third parties.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to update Privacy Policy. 
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns about this Privacy Policy, please feel free to contact us.
        </Typography>
      </Box>
    </Container>
    </Box>

  );
};

export default PrivacyPolicy;
