import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Methodology: React.FC = () => {
  return (
    // Outer Box to hold the background image
    <Box
      sx={{
        backgroundImage: `url('/mybird.jpg')`, // Reference to the image in the public folder
        backgroundSize: 'cover', // Ensures the image covers the entire background
        backgroundPosition: 'center', // Center the image
        backgroundRepeat: 'no-repeat', // Avoid image repetition
        minHeight: '100vh', // Ensures the background fills the full viewport height
        padding: '40px 20px',
      }}
    >
      {/* Container for the content with a slight opacity to make text readable */}
      <Container
        maxWidth="md"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.85)', // White background with transparency
          padding: '40px 20px',
          borderRadius: '10px', // Rounded corners
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Model Methodology
        </Typography>

        <Typography variant="body1" paragraph>
          The model used for bird species identification is a Vision Transformer (ViT) model, which is state-of-the-art for image classification tasks. ViT leverages attention mechanisms typically used in Natural Language Processing (NLP) models to focus on different parts of the input image, resulting in a highly accurate classification of bird species.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Model Architecture
        </Typography>
        <Typography variant="body1" paragraph>
          The Vision Transformer (ViT) is based on the Transformer architecture, which divides images into patches (in our case, 16x16 pixels) and treats them similarly to tokens in NLP tasks. The model learns how different patches of the image relate, making it particularly effective for distinguishing between similar bird species.
        </Typography>

        {/* Image demonstrating the patching process */}
        <Box display="flex" justifyContent="center" my={4}>
          <img 
            src="/patching.png" 
            alt="Image showing how input image is divided into patches" 
            style={{ width: '80%', maxWidth: '600px', borderRadius: '10px' }} 
          />
        </Box>
        <Typography variant="body2" align="center" gutterBottom>
          Figure: Input image is divided into 16x16 pixel patches before being processed by the model.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Unrolling Patches
        </Typography>
        <Typography variant="body1" paragraph>
          After dividing the image into patches, the patches are "unrolled" into a sequence and fed into the Transformer model. This allows the model to learn the relationships between patches and identify the bird species based on various features.
        </Typography>

        {/* Image showing unrolling process */}
        <Box display="flex" justifyContent="center" my={4}>
          <img 
            src="/unrolled.png" 
            alt="Image showing how patches are unrolled before being fed into the Transformer" 
            style={{ width: '80%', maxWidth: '600px', borderRadius: '10px' }} 
          />
        </Box>
        <Typography variant="body2" align="center" gutterBottom>
          Figure: Patches are unrolled and fed into the Transformer model for further processing.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Dataset
        </Typography>
        <Typography variant="body1" paragraph>
          The model is trained on the Caltech-UCSD Birds 200 (CUB-200-2011) dataset, which contains 11,788 images of 200 distinct bird species. The dataset is well-known in the computer vision community and is often used for benchmarking image classification algorithms.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Preprocessing
        </Typography>
        <Typography variant="body1" paragraph>
          Each bird image is resized to 224x224 pixels, and we apply standard data augmentation techniques such as random cropping, flipping, and normalization. These augmentations help the model generalize better by simulating variations in the bird images, such as different angles and lighting conditions.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Training Process
        </Typography>
        <Typography variant="body1" paragraph>
          The model was trained using PyTorch, with an Adam optimizer and a learning rate of 1e-4. The training process lasted for 5 epochs, with early stopping implemented to prevent overfitting. A train-test split provided via the dataset was used to check model performance, ensuring the model generalizes well to unseen images.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Evaluation
        </Typography>
        <Typography variant="body1" paragraph>
          The model achieved an accuracy of 99.5% on the test set, making it one of the top-performing models for bird species classification on this dataset. We evaluated the model using standard metrics such as accuracy, precision, recall, and F1-score, with the majority of misclassifications occurring between very similar species.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Future Work
        </Typography>
        <Typography variant="body1" paragraph>
          We plan to enhance the model by incorporating additional data sources and experimenting with more advanced architectures such as EfficientNet and ResNet. Additionally, implementing fine-grained visual analysis could help distinguish between species with only subtle differences.
        </Typography>
      </Container>
    </Box>
  );
};

export default Methodology;
