import React from 'react';
import { Container, Typography, Link, Box } from '@mui/material';

const Disclaimer: React.FC = () => {
  return (
    // Outer Box for background image
    <Box
      sx={{
        backgroundImage: `url('/mybird.jpg')`, // Background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slight transparency for readability
          borderRadius: '12px',
          boxShadow: 3,
          padding: '40px',
          textAlign: 'left',
        }}
      >
        <Typography variant="h4" gutterBottom align="center" color="primary">
          Disclaimer
        </Typography>

        <Typography variant="body1" paragraph>
          The SmartBirds project utilizes images and data from the{' '}
          <Link
            href="https://www.vision.caltech.edu/datasets/cub_200_2011/"
            target="_blank"
            rel="noopener noreferrer"
            color="secondary"
            underline="hover"
          >
            Caltech-UCSD Birds-200-2011 (CUB-200-2011) dataset
          </Link>
          . These images are used solely for educational and research purposes.
        </Typography>

        <Typography variant="body1" paragraph color="error">
          <strong>Warning:</strong> Images in this dataset overlap with images in ImageNet, which makes evaluation of the test data not so simple when using pre-trained models.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Details:</strong> <br />
          The Caltech-UCSD Birds-200-2011 (CUB-200-2011) is a larger version of the CUB-200 dataset. The citation for the technical report is Welinder P., Branson S., Mita T., Wah C., Schroff F., Belongie S., Perona, P. "Caltech-UCSD Birds 200". California Institute of Technology. CNS-TR-2010-001. 2010.
        </Typography>

        <Typography variant="body1" paragraph>
          <ul>
            <li>Number of categories: 200</li>
            <li>Number of images: 11,788</li>
            <li>Annotations per image: 15 Part Locations, 312 Binary Attributes, 1 Bounding Box per image</li>
          </ul>
          For detailed information about the dataset, visit the original webpage for the Caltech bird dataset.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Data Information:</strong> <br />
          <ul>
            <li>Images and annotations (1.1 GB)</li>
            <li>Segmentations (37 MB): The segmentations were created by Ryan Farrell.</li>
          </ul>
        </Typography>
      </Container>
    </Box>
  );
};

export default Disclaimer;
